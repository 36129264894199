import React, { Suspense, useEffect, useState } from 'react';

const ShippingAccountForm = React.lazy(async () => {
  return await import("shipping/ShippingAccountFormContainer");
});

const BaseDialog = React.lazy(async () => {
  return await import("shipping/BaseDialog");
})

const ShippingAccountModal = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen)

  useEffect(() => {
    const handleModalOpen = (event) => {
      if (parseInt(event.detail.collectAccountId) === props.collectAccount.id) {
        setIsOpen(true)
      }
    }

    window.addEventListener('openShippingAccountModal', handleModalOpen);

    return () => {
      window.removeEventListener('openShippingAccountModal', handleModalOpen);
    }
  }, [props.collectAccountId])

  const handleClose = () => {
    setIsOpen(false);
  }

  const handleComplete = () => {
    setIsOpen(false);
    // Reload the page to get the updated shipping account information
    window.location.reload();
  }

  return (
    <div>
      <Suspense>
        <BaseDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          panelClasses='tw-w-[580px]'
        >
          <Suspense>
            <ShippingAccountForm
              onClose={handleClose}
              onComplete={handleComplete}
              customer={props.customer}
              customerId={props.customerId}
              shippingAccount={props.collectAccount}
            />
          </Suspense>
        </BaseDialog>
      </Suspense>
    </div>
  )
}

export default ShippingAccountModal;
import React, { Suspense } from 'react';
import Logo from './logo'

const ShippingContainer = React.lazy(async () => {
  return await import("shipping/ShippingContainer");
});

const ShippingComponent = (props) => {
  return (
    <div style={{ minHeight: 'calc(100vh - 397px)', textAlign: 'center', paddingTop: '40px' }}>
      <Suspense fallback={'Loading...'}>
        <ShippingContainer
          logo={<Logo />}
          {...props}
        />
      </Suspense>
    </div>
  )
}

export default ShippingComponent
import React, { Suspense } from 'react';
import Skeleton from './loading_skeleton';

const ProductCatalogContainer = React.lazy(async () => {
  return await import("productCatalog/ProductCatalogContainer");
});

const SkeletonLayout = () => {
  return (
    <div className="skeleton-layout">
      <Skeleton height="3rem" width="100%" />
      <Skeleton height="1.5rem" width="100%" />
      <Skeleton height="1.5rem" width="100%" />
      <Skeleton height="1.5rem" width="100%" />
    </div>
  );
};

const ProductCatalogComponent = (props) => {
  return (
    <div id='facets-component'>
      <Suspense fallback={<SkeletonLayout />}>
        <ProductCatalogContainer {...props} />
      </Suspense>
    </div>
  );
};

export default ProductCatalogComponent;